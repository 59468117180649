@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

.progress-wrap {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset  0 0 0 2px rgba(95, 58, 252,0.2);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.progress-wrap {
    &.active-progress {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &::after {
        position: absolute;
        content: '\f176';
        font-family: "Font Awesome 5 Pro";
        text-align: center;
        line-height: 46px;
        font-size: 20px;
        color: $theme-color;
        left: 0;
        top: 0;
        height: 46px;
        width: 46px;
        cursor: pointer;
        display: block;
        z-index: 1;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
    }
    svg {
        path {
            fill: none;
        }
        &.progress-circle path {
            stroke: $theme-color;
            stroke-width: 4;
            box-sizing:border-box;
            -webkit-transition: all 200ms linear;
            transition: all 200ms linear;
        }
    }
}
